import * as ActiveStorage from '@rails/activestorage';
import RailsUjs from '@rails/ujs';
import Turbolinks from 'turbolinks';

void main();

// Callback invoked when we've asynchronously loaded the javascript
// from Google Maps API. Listeners can then be made aware that
// Google's javascript has finished loading.
function initGoogleMapsPlaces(...args) {
  const event = document.createEvent('Events');
  event.initEvent('google-maps-places-loaded', true, true);
  event.args = args;
  window.dispatchEvent(event);
}

async function main() {
  RailsUjs.start();
  Turbolinks.start();
  ActiveStorage.start();

  window.initGoogleMapsPlaces = initGoogleMapsPlaces;
  await import('../channels');
  await import('../controllers');
}
